import Ably from "ably";

export default {
  data() {
    return {
      ablyConnection: null,
    };
  },
  methods: {
    ablyInitialize() {
      if (!this.ablyConnection) {
        this.ablyConnection = new Ably.Realtime(process.env.VUE_APP_ABLY_KEY);
      }

      this.ablyConnection.connection.once("connected", () => {
        console.log("Connected to Ably!");
      });

      this.ablyConnection.connection.once("failed", function () {
        console.log("failed to Connect!");
      });
    },
    async channelUnsubscribe(channelName, eventName, callback) {
      this.ablyInitialize();
      console.log("channelUnsubscribe");
      const channel = this.ablyConnection.channels.get(channelName);
      await channel.unsubscribe(eventName, callback);
    },
    async channelSubscribe(channelName, eventName, callback) {
      this.ablyInitialize();
      console.log("channelSubscribe");
      const channel = this.ablyConnection.channels.get(channelName);
      await channel.subscribe(eventName, callback);
    },
  },
};
