import { render, staticRenderFns } from "./ResourceCalendar.vue?vue&type=template&id=2930546a&scoped=true"
import script from "./ResourceCalendar.vue?vue&type=script&lang=js"
export * from "./ResourceCalendar.vue?vue&type=script&lang=js"
import style0 from "./ResourceCalendar.vue?vue&type=style&index=0&id=2930546a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2930546a",
  null
  
)

export default component.exports