<template>
  <div>
    <FullDialog
      dense
      :dialog="calendarDialog"
      v-on:close="calendarDialog = false"
      :back-route="{ name: 'admin.dashboard' }"
    >
      <template v-slot:title>
        <v-toolbar height="34" flat class="bg-transparent py-0">
          <v-btn
            outlined
            width="30"
            min-width="30"
            height="26"
            color="grey darken-4"
            class="white--text ml-3 rounded"
            @click="toggleVisit = !toggleVisit"
          >
            <v-icon size="20">{{
              toggleVisit
                ? "mdi-format-indent-increase"
                : "mdi-format-indent-decrease"
            }}</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:body>
        <v-row class="mx-0 position-relative">
          <v-col md="12">
            <div
              class="left-pannel d-flex align-top"
              style="top: 10px; left: 10px; position: absolute; z-index: 2"
            >
              <v-card
                v-if="!isLeftCollapsePanel"
                class="engineer-list rounded-0"
                outlined
                width="300"
              >
                <v-list class="left-pannel-filter">
                  <div class="d-flex align-center mr-2 w-100">
                    <v-subheader class="px-3 py-3 text-h6 w-100">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            class="black--text d-flex align-center w-100"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <span>{{ selectEng }}</span>
                            <v-avatar
                              class="mx-4"
                              size="23"
                              :color="engStatusColor"
                            >
                              <span class="white--text font-weight-medium">{{
                                selectEngCount
                              }}</span>
                            </v-avatar>
                            <v-icon>mdi-chevron-down</v-icon>
                          </span>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(item, index) in engineerStatus"
                            :key="index"
                            @click="filterEngineer(item)"
                          >
                            <v-list-item-title
                              ><v-icon
                                v-if="item.title !== 'All Engineer'"
                                class="me-1 mb-1"
                                small
                                :color="item.color"
                                >mdi-access-point</v-icon
                              >{{ item.title }}</v-list-item-title
                            >
                            <v-list-item-avatar class="my-0">
                              <v-avatar size="23" :color="item.color"
                                ><span class="white--text font-weight-medium">{{
                                  item.count
                                }}</span></v-avatar
                              >
                            </v-list-item-avatar>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-subheader>
                  </div>
                  <v-divider class="mb-0"></v-divider>
                  <div class="search-engineer ma-2">
                    <v-text-field
                      v-model="engineer_search"
                      dense
                      filled
                      placeholder="search..."
                      append-icon="mdi-magnify"
                      solo
                      flat
                      hide-details
                      color="cyan"
                    ></v-text-field>
                  </div>
                  <v-divider class="mb-0"></v-divider>
                  <div style="height: 75vh; overflow: auto">
                    <v-list-item
                      v-for="(engineer, index) in filteredEngineers"
                      :key="index"
                      @mouseover="hoverMarkerEngineer(index)"
                      @mouseout="clearHoverMarkerEngineer"
                      :class="{
                        'active-card': activeMarkerIndex === index,
                        'border-bottom': index !== engineers.length - 1,
                      }"
                    >
                      <v-list-item-avatar>
                        <v-img :src="engineer.avatar" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          >{{ engineer.name }}
                          <v-icon :color="engineer.online ? 'green' : 'red'"
                            >mdi-access-point</v-icon
                          ></v-list-item-title
                        >
                        <v-list-item-subtitle>{{
                          engineer.profile
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-avatar
                        ><v-avatar size="20" class="white--text" color="blue">{{
                          engineer.visits
                        }}</v-avatar>
                      </v-list-item-avatar>
                    </v-list-item>
                  </div>
                </v-list>
              </v-card>
              <v-btn
                class="border"
                width="40"
                min-height="40"
                @click="isLeftCollapsePanel = !isLeftCollapsePanel"
              >
                <v-icon size="35" color="blue">{{
                  isLeftCollapsePanel ? "mdi-chevron-right" : "mdi-chevron-left"
                }}</v-icon>
              </v-btn>
            </div>
            <div
              class="google-map position-relative"
              style="width: 100%; height: 90vh"
            >
              <div class="map-filter d-flex align-center w-50">
                <v-autocomplete
                  :items="engineerStatus"
                  v-model="engFilter"
                  hide-details
                  clearable
                  flat
                  solo
                  dense
                  placeholder="Filter Engineer"
                  color="blue darken-4"
                  class="pa-0 mx-1"
                  item-text="title"
                  item-value="id"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content dense class="text-truncate">
                      <v-list-item-title v-html="item.title" />
                    </v-list-item-content>
                    <v-list-item-icon dense class="my-2 pl-2 pr-0">
                      <v-icon
                        class="white--text"
                        :color="item.color"
                        label
                        small
                      >
                        mdi-circle
                      </v-icon>
                    </v-list-item-icon>
                  </template>
                </v-autocomplete>
                <v-text-field
                  hide-details
                  class="px-0 mx-1"
                  v-model="mapSearch"
                  placeholder="Search"
                  flat
                  solo
                  dense
                  append-icon="mdi-magnify"
                  color="blue darken-4"
                />
                <v-autocomplete
                  hide-details
                  flat
                  solo
                  dense
                  v-model="selectedTimeZone"
                  :items="timeZones"
                  placeholder="Filter by Time Zone"
                  color="blue darken-4"
                  class="px-0 mx-1"
                ></v-autocomplete>
                <v-autocomplete
                  :items="filterEngVisit"
                  v-model="selectEngVisit"
                  hide-details
                  clearable
                  flat
                  solo
                  dense
                  placeholder="Filter Engineer Jobs"
                  color="blue darken-4"
                  item-color="blue darken-4"
                  class="pa-0 mx-1"
                />
                <v-autocomplete
                  :items="jobFilterList"
                  v-model="jobFilter"
                  hide-details
                  clearable
                  flat
                  solo
                  dense
                  placeholder="Jobs Filter"
                  color="blue darken-4"
                  item-color="blue darken-4"
                  item-text="title"
                  item-value="id"
                  class="pa-0 mx-1"
                >
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content dense class="text-truncate">
                        <v-list-item-title v-html="data.item.title" />
                      </v-list-item-content>
                      <v-list-item-icon dense class="my-2 pl-2 pr-0">
                        <v-icon
                          class="white--text"
                          :color="data.item.color"
                          label
                          small
                          >mdi-circle
                        </v-icon>
                      </v-list-item-icon>
                    </template>
                  </template>
                </v-autocomplete>
              </div>
              <GmapMap
                ref="map"
                :center="mapCenter"
                :zoom="mapZoom"
                style="width: 100%; height: 100%"
                @ready="onMapReady"
              >
                <GmapCluster :minimumClusterSize="2" :zoomOnClick="true">
                  <GmapMarker
                    v-for="(marker, index) in markers"
                    :key="index"
                    :position="marker.position"
                    :clickable="true"
                    @mouseover="openInfoWindow(index)"
                    @mouseout="closeInfoWindow"
                    class="map_marker"
                    :icon="markerIcon(marker, index)"
                  />
                </GmapCluster>
                <GmapInfoWindow
                  v-if="infoWindowOpened"
                  :position="markers[activeMarkerIndex].position"
                  :options="{ pixelOffset: { width: 0, height: -40 } }"
                  class="px-0"
                >
                  <div class="custom-info-window">
                    <template
                      v-if="
                        markers[activeMarkerIndex].type === 'engineer' ||
                        hoveredType === 'engineer'
                      "
                    >
                      <v-toolbar
                        elevation="0"
                        :color="`${markers[activeMarkerIndex].content.visitStatus} lighten-5`"
                      >
                        <v-toolbar-title class="text-h6">
                          <v-list-item>
                            <v-badge
                              bordered
                              bottom
                              :color="
                                markers[activeMarkerIndex].content.online
                                  ? 'green'
                                  : 'red'
                              "
                              dot
                              offset-x="10"
                              offset-y="10"
                              class="me-2"
                            >
                              <v-avatar size="40">
                                <v-img
                                  :src="
                                    markers[activeMarkerIndex].content.avatar
                                  "
                                ></v-img>
                              </v-avatar>
                            </v-badge>
                            <v-list-item-content>
                              <v-list-item-title>{{
                                markers[activeMarkerIndex].content.name
                              }}</v-list-item-title>
                              <v-list-item-subtitle
                                >{{
                                  markers[activeMarkerIndex].content.profile
                                }}
                                <div>
                                  <v-icon size="16">mdi-calendar</v-icon
                                  >18/03/2025
                                </div></v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <div class="total-visits">
                          <!-- Engineer Visits Summary -->
                          <div class="d-flex align-center justify-end mb-1">
                            <div class="font-weight-medium me-2">
                              Total Visits :
                            </div>
                            <v-avatar
                              class="white--text"
                              color="blue"
                              size="25"
                            >
                              <span class="font-weight-medium">
                                4
                                <!-- {{
                                markers[activeMarkerIndex].content.visits.length
                              }} -->
                              </span>
                            </v-avatar>
                          </div>
                          <div class="d-flex align-center justify-content-end">
                            <div class="font-weight-medium me-2">
                              Overdue Visits :
                            </div>
                            <v-avatar class="white--text" color="red" size="25">
                              <span class="font-weight-medium">1</span>
                            </v-avatar>
                          </div>
                        </div>
                      </v-toolbar>
                      <div class="px-2 py-2">
                        <!-- Table for Engineer's Job Visits -->
                        <table width="100%" cellpadding="0" cellspacing="0">
                          <tr
                            v-for="(visit, index) in markers[activeMarkerIndex]
                              .content.visits"
                            :key="index"
                            :class="index % 2 === 0 ? 'grey lighten-4' : ''"
                          >
                            <td>
                              <v-chip
                                :color="visit.visitStatus"
                                label
                                x-small
                                text-color="white"
                              >
                                {{ visit.visitId }}
                              </v-chip>
                            </td>
                            <td width="150px">
                              <div
                                class="d-inline-block text-truncate"
                                style="max-width: 150px"
                              >
                                {{ visit.title }}
                              </div>
                            </td>
                            <td>
                              <v-icon :color="visit.visitStatus" small
                                >mdi-map-marker</v-icon
                              >
                              {{ visit.location }}
                            </td>
                            <td>
                              <div>
                                <v-icon class="me-1" small
                                  >mdi-clock-outline</v-icon
                                >
                                {{ visit.time }}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </template>

                    <!-- Job Visit Info Design -->
                    <template
                      v-else-if="
                        markers[activeMarkerIndex].type === 'job' ||
                        hoveredType === 'job'
                      "
                    >
                      <v-toolbar
                        elevation="0"
                        :color="`${markers[activeMarkerIndex].content.visitStatus} lighten-5`"
                      >
                        <v-toolbar-title class="text-h6">
                          Job Visit: #{{
                            markers[activeMarkerIndex].content.visitId
                          }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-icon small color="green">mdi-circle</v-icon>
                      </v-toolbar>
                      <div class="px-2 py-2">
                        <div
                          class="d-flex align-center justify-content-start mb-2"
                        >
                          <div class="fw-600 me-1">Priority:</div>
                          <v-spacer></v-spacer>
                          <v-chip small color="green" outlined label>
                            {{ markers[activeMarkerIndex].content.priority }}
                          </v-chip>
                        </div>
                        <div
                          class="d-flex align-center justify-content-start mb-2"
                        >
                          <div class="fw-600 me-1">Status:</div>
                          <v-spacer></v-spacer>
                          <v-chip color="blue" small label text-color="white">
                            {{ markers[activeMarkerIndex].content.status }}
                          </v-chip>
                        </div>
                        <div
                          class="d-flex align-center justify-content-start mb-2"
                        >
                          <div class="fw-600 me-1">Title:</div>
                          <v-spacer></v-spacer>
                          <div>
                            {{ markers[activeMarkerIndex].content.title }}
                          </div>
                        </div>
                        <div
                          class="d-flex align-center justify-content-start mb-2"
                        >
                          <div class="fw-600 me-1">Time:</div>
                          <v-spacer></v-spacer>
                          <div>
                            <v-icon small>mdi-map-clock</v-icon>
                            {{ markers[activeMarkerIndex].content.time }}
                          </div>
                        </div>
                        <div
                          class="d-flex align-center justify-content-start mb-2"
                        >
                          <div class="fw-600 me-1">Allocated Engineer:</div>
                          <v-spacer></v-spacer>
                          <div v-if="markers[activeMarkerIndex].content.team">
                            <span
                              v-for="(team, index) in markers[activeMarkerIndex]
                                .content.team"
                              :key="index"
                              >{{ team }},</span
                            >
                          </div>
                          <div class="text-sm" v-else>Not assigned.</div>
                        </div>
                      </div>
                    </template>
                  </div>
                </GmapInfoWindow>
              </GmapMap>
              <div class="legent-list">
                <v-row>
                  <v-col md="7" class="mx-auto py-0">
                    <div
                      class="legends d-flex flex-wrap align-center justify-left my-2"
                    >
                      <div
                        class="px-2 mr-3 py-1 d-flex align-center border rounded"
                        style="border-color: cyan"
                      >
                        <span class="text-black font-weight-700"
                          >Engineers :</span
                        >
                      </div>
                      <div
                        class="px-2 mr-3 py-1 d-flex align-center border rounded"
                      >
                        <i
                          aria-hidden="true"
                          class="v-icon notranslate mr-1 mdi mdi-access-point theme--light green--text text--darken-1"
                          style="font-size: 15px"
                        ></i
                        ><span class="text-black">Online</span>
                      </div>
                      <div
                        class="px-2 mr-3 py-1 d-flex align-center border rounded"
                      >
                        <i
                          aria-hidden="true"
                          class="v-icon notranslate mr-1 mdi mdi-access-point theme--light red--text text--darken-1"
                          style="font-size: 15px"
                        ></i
                        ><span class="text-black">Offline</span>
                      </div>
                    </div>
                    <div
                      class="legends d-flex flex-wrap align-center justify-left my-2"
                    >
                      <div
                        class="px-2 mr-3 py-1 d-flex align-center border rounded"
                        style="border-color: cyan"
                      >
                        <i
                          aria-hidden="true"
                          class="v-icon notranslate mr-1 mdi mdi-pin theme--light cyan--text"
                          style="font-size: 15px"
                        ></i
                        ><span class="text-black font-weight-700">Visit :</span>
                      </div>
                      <div
                        class="px-2 mr-3 py-1 d-flex align-center border rounded"
                      >
                        <i
                          aria-hidden="true"
                          class="v-icon notranslate mr-1 mdi mdi-pin theme--light blue--text text--darken-1"
                          style="font-size: 15px"
                        ></i
                        ><span class="text-black">Open</span>
                      </div>
                      <div
                        class="px-2 mr-3 py-1 d-flex align-center border rounded"
                        style="border-color: yellow"
                      >
                        <i
                          aria-hidden="true"
                          class="v-icon notranslate mr-1 mdi mdi-pin theme--light yellow--text"
                          style="font-size: 15px"
                        ></i
                        ><span class="text-black">In-Progress</span>
                      </div>
                      <div
                        class="px-2 mr-3 py-1 d-flex align-center border rounded"
                      >
                        <i
                          aria-hidden="true"
                          class="v-icon notranslate mr-1 mdi mdi-pin theme--light orange--text text--darken-4"
                          style="font-size: 15px"
                        ></i
                        ><span class="text-black">Hold &amp; Stay Assign</span>
                      </div>
                      <div
                        class="px-2 mr-3 py-1 d-flex align-center border rounded"
                      >
                        <i
                          aria-hidden="true"
                          class="v-icon notranslate mr-1 mdi mdi-pin theme--light green--text text--darken-1"
                          style="font-size: 15px"
                        ></i
                        ><span class="text-black">Completed</span>
                      </div>
                      <div
                        class="px-2 mr-3 py-1 d-flex align-center border rounded"
                      >
                        <i
                          aria-hidden="true"
                          class="v-icon notranslate mr-1 mdi mdi-pin theme--light red--text text--lighten-1"
                          style="font-size: 15px"
                        ></i
                        ><span class="text-black">Cancelled</span>
                      </div>
                      <div
                        class="px-2 mr-3 py-1 d-flex align-center border rounded"
                      >
                        <i
                          aria-hidden="true"
                          class="v-icon notranslate mr-1 mdi mdi-pin theme--light orange--text text--darken-4"
                          style="font-size: 15px"
                        ></i
                        ><span class="text-black">Hold &amp; Unassigned</span>
                      </div>
                    </div>
                    <div class="d-flex align-center justify-content-end fw-700">
                      <span>Powerd By</span>
                      <v-img
                        contain
                        width="20"
                        max-width="20"
                        height="20"
                        src="https://fsm-24.genicteams.com/favicon-32x32.png"
                        class="mx-2"
                      ></v-img>
                      <span>Genic Teams</span>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div
              class="right-pannel d-flex align-top"
              style="top: 10px; right: 10px; position: absolute; z-index: 2"
            >
              <v-btn
                class="border"
                width="40"
                min-height="40"
                @click="isRightCollapsePanel = !isRightCollapsePanel"
              >
                <v-icon size="35" color="blue">{{
                  isRightCollapsePanel
                    ? "mdi-chevron-left"
                    : "mdi-chevron-right"
                }}</v-icon>
              </v-btn>
              <v-card
                class="rounded-0"
                width="300"
                outlined
                v-if="!isRightCollapsePanel"
              >
                <v-list>
                  <div class="d-flex align-center w-100 mr-2">
                    <v-subheader class="px-3 py-3 text-h6 w-100"
                      ><v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            class="black--text d-flex align-center w-100"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <span>{{ selectJobStatus }}</span>
                            <v-avatar
                              class="mx-4"
                              size="23"
                              :color="selectjobColor"
                              ><span class="white--text font-weight-medium">{{
                                selectJobCount
                              }}</span></v-avatar
                            >
                            <!-- <v-spacer></v-spacer> -->
                            <v-icon>mdi-chevron-down</v-icon>
                          </span>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(item, index) in jobStatus"
                            :key="index"
                            @click="selectJobs(item)"
                          >
                            <v-list-item-avatar class="my-0">
                              <v-avatar size="23" :color="item.color"
                                ><span class="white--text font-weight-medium">{{
                                  item.count
                                }}</span></v-avatar
                              >
                            </v-list-item-avatar>
                            <v-list-item-title>{{
                              item.title
                            }}</v-list-item-title>
                            <v-list-item-icon class="py-0">
                              <v-icon small :color="item.color">mdi-pin</v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list>
                      </v-menu></v-subheader
                    >
                    <!-- <v-spacer> </v-spacer>
                    <v-avatar size="30" color="blue"
                      ><span class="white--text font-weight-medium">{{
                        visits.length
                      }}</span></v-avatar
                    > -->
                  </div>
                  <v-divider class="mb-0"></v-divider>
                  <div class="search-engineer ma-2">
                    <v-text-field
                      v-model="jobs_search"
                      dense
                      filled
                      placeholder="search..."
                      append-icon="mdi-magnify"
                      solo
                      flat
                      hide-details
                      color="cyan"
                    ></v-text-field>
                  </div>
                  <v-divider class="mb-0"></v-divider>
                  <div style="height: 75vh; overflow: auto">
                    <draggable v-model="visits" class="div">
                      <template v-for="(visit, index) in visits">
                        <v-card
                          :key="index"
                          class="ma-0 cursor-move rounded-0"
                          :style="`border-left: 4px solid ${visit.visitStatus}`"
                          elevation="0"
                          @mouseover="visithoverMarker(index)"
                          @mouseout="clearVisitHoverMarker"
                          :class="{
                            'active-car': activeMarkerIndex === index,
                            'border-bottom': index !== engineers.length - 1,
                          }"
                        >
                          <v-card-text>
                            <div class="d-flex align-center">
                              <span
                                class="fw-700 blue--text text-darken-4"
                                style="font-size: 16px"
                              >
                                {{ visit.code }}
                              </span>
                              <v-spacer></v-spacer>
                              <div>
                                <div class="green--text">
                                  <v-icon color="green" x-small
                                    >mdi-alarm</v-icon
                                  >
                                  {{ visit.startDate }}
                                </div>
                                <div class="red--text">
                                  <v-icon color="red" x-small>mdi-alarm</v-icon>
                                  {{ visit.endDate }}
                                </div>
                              </div>
                            </div>
                            <div class="d-flex align-center">
                              <div class="text-truncate d-block fw-500">
                                {{ visit.description }}
                              </div>
                              <v-spacer></v-spacer>
                              <v-icon small :color="visit.visitStatus"
                                >mdi-circle</v-icon
                              >
                            </div>
                          </v-card-text>
                        </v-card>
                      </template>
                    </draggable>
                  </div>
                </v-list>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </template>
    </FullDialog>

    <DetailDrawer
      :drawer="open_detail"
      v-on:close="open_detail = false"
    ></DetailDrawer>
    <SingleVisitDetail
      v-if="viewVisitDialog"
      :visit-dialog="viewVisitDialog"
      :visit="visitId"
      type="resource"
      v-on:close="viewVisitDialog = false"
    ></SingleVisitDetail>
  </div>
</template>
<script>
import DetailDrawer from "@/view/pages/calendar/DetailDrawer";
import SingleVisitDetail from "@/view/pages/job/partials/Single-Visit-Detail.vue";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import CommonMixin from "@/core/plugins/common-mixin";
import FullDialog from "@/view/components/FullDialog2.vue";
import AblyMixin from "@/core/plugins/ably.mixin";
import moment from "moment-timezone";
import Draggable from "vuedraggable";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [AblyMixin, CommonMixin],
  name: "engineer-google-map",
  components: {
    GmapCluster,
    FullDialog,
    DetailDrawer,
    SingleVisitDetail,
    Draggable,
  },
  data() {
    return {
      base64Image: "",
      selectJobStatus: "Unassigned Jobs",
      selectJobCount: 1,
      selectjobColor: "orange",
      isLeftCollapsePanel: false,
      isRightCollapsePanel: false,
      jobs_search: null,
      engineer_search: null,
      map: null,
      selectEng: "All Engineer",
      selectEngCount: 9,
      engStatusColor: "blue darken-4",
      mapSearch: "",
      timeZones: ["GMT+8", "GMT+7", "GMT+9"],
      selectedTimeZone: "",
      selectEngVisit: "",
      filterEngVisit: ["Only Show Engineer", "Only Show Visits"],
      engineerStatus: [
        { title: "All Engineer", color: "blue darken-4", count: 9 },
        { title: "Active Engineer", color: "green", count: 7 },
        { title: "In-active Engineer", color: "red", count: 2 },
      ],
      jobStatus: [
        { title: "Unassignd Jobs", color: "orange", count: 1 },
        { title: "Assignd Jobs", color: "orange", count: 1 },
        { title: "Open Jobs", color: "blue", count: 1 },
        { title: "In-Progress Jobs", color: "yellow", count: 1 },
        { title: "Check-In", color: "green", count: 1 },
      ],
      engFilter: "",
      jobFilter: "",
      jobFilterList: [
        { title: "Unassignd Jobs", color: "orange" },
        { title: "Assignd Jobs", color: "orange" },
        { title: "Open Jobs", color: "green" },
        { title: "In-Progress Jobs", color: "yellow" },
        { title: "Check-In", color: "orange" },
      ],
      position: { lat: 1.3557, lng: 103.7453 },
      mapZoom: 12,
      markers: [
        {
          type: "engineer",
          position: { lat: 1.3521, lng: 103.8198 },
          img: "/media/users/100_1.jpg",
          visitStatus: "#2588e5",
          content: {
            visitId: 145,
            name: "John Doe",
            profile: "Chief Engineer",
            avatar: "/media/users/100_1.jpg",
            visitStatus: "#2588e5",
            online: true,
            visits: [
              {
                location: "Singapore Business Park",
                visitStatus: "blue",
                visitId: "#Visit-1452",
                title: "Aircon Repair3",
                time: "09:30AM - 10:30AM",
              },
              {
                location: "Singapore Business Park",
                visitStatus: "blue",
                visitId: "#Visit-1453",
                title: "Aircon Repair4",
                time: "09:30AM - 10:30AM",
              },
              {
                location: "Singapore Business Park",
                visitStatus: "blue",
                visitId: "#Visit-1454",
                title: "Aircon Repair5",
                time: "09:30AM - 10:30AM",
              },
              {
                location: "Singapore Business Park",
                visitStatus: "blue",
                visitId: "#Visit-1455",
                title: "Aircon Repair6",
                time: "09:30AM - 10:30AM",
              },
              {
                location: "Singapore Business Park",
                visitStatus: "blue",
                visitId: "#Visit-1456",
                title: "Aircon Repair7",
                time: "09:30AM - 10:30AM",
              },
            ],
          },
        },
        {
          type: "engineer",
          position: { lat: 1.3219, lng: 103.8185 },
          img: "/media/users/unknown.png",
          visitStatus: "red",
          content: {
            visitId: 146,
            name: "Michael Brown",
            profile: "Senior Engineer",
            avatar: "/media/users/unknown.png",
            visitStatus: "red",
            online: true,
            visits: [
              {
                location: "Singapore Business Park 2",
                visitStatus: "red",
                visitId: "#Visit-146",
                title: "Lift Repair",
                time: "8:30AM - 10:30AM",
              },
            ],
          },
        },
        {
          type: "engineer",
          position: { lat: 1.3557, lng: 103.8453 },
          img: "/media/users/100_2.jpg",
          visitStatus: "blue",
          content: {
            visitId: 147,
            name: "Michela",
            profile: "Lead Engineer",
            avatar: "/media/users/100_2.jpg",
            visitStatus: "blue",
            online: false,
            visits: [
              {
                location: "Singapore Business Park 2",
                visitStatus: "blue",
                visitId: "#Visit-147",
                title: "Server Room Reapir",
                time: "8:30AM - 10:30AM",
              },
            ],
          },
        },
        {
          type: "engineer",
          position: { lat: 1.2902, lng: 103.8474 },
          img: "/media/users/100_3.jpg",
          visitStatus: "blue",
          content: {
            visitId: 148,
            name: "Anderson",
            profile: "Lead Engineer",
            avatar: "/media/users/100_3.jpg",
            visitStatus: "blue",
            online: false,
            visits: [
              {
                location: "Singapore Business Park 2",
                visitStatus: "blue",
                visitId: "#Visit-148",
                title: "title 1",
                time: "8:30AM - 10:30AM",
              },
            ],
          },
        },
        {
          type: "engineer",
          position: { lat: 1.2621, lng: 103.8298 },
          img: "/media/users/unknown.png",
          visitStatus: "blue",
          content: {
            visitId: 148,
            name: "Michael",
            profile: "Senior Engineer",
            avatar: "/media/users/unknown.png",
            online: false,
            visitStatus: "blue",
            visits: [
              {
                location: "Singapore Business Park 2",
                visitStatus: "blue",
                visitId: "#Visit-149",
                title: "Aircon Repair",
                time: "8:30AM - 10:30AM",
              },
            ],
          },
        },
        {
          type: "engineer",
          position: { lat: 1.3621, lng: 103.9298 },
          img: "/media/users/100_5.jpg",
          visitStatus: "blue",
          content: {
            visitId: 150,
            name: "Jane Smith",
            profile: "Lead Engineer",
            avatar: "/media/users/100_5.jpg",
            online: false,
            visitStatus: "blue",
            visits: [
              {
                location: "Singapore Business Park 2",
                visitStatus: "blue",
                visitId: "#Visit-150",
                title: "Escalator Engineer",
                time: "8:30AM - 10:30AM",
              },
            ],
          },
        },
        {
          type: "job",
          position: { lat: 1.3657, lng: 103.8453 },
          visitStatus: "green",
          content: {
            visitId: 146,
            title: "Installation at Site B",
            priority: "Low",
            status: "Open",
            visitStatus: "green",
            time: "01/10/2024 03:00 PM - 01/10/2024 04:00 PM",
            team: [" Alex Johnson", "John Doe"],
          },
        },
        {
          type: "job",
          position: { lat: 1.4096, lng: 103.7633 },
          visitStatus: "red",
          content: {
            visitId: 149,
            title: "Installation at Site B",
            priority: "Low",
            status: "Open",
            visitStatus: "red",
            time: "01/10/2024 03:00 PM - 01/10/2024 04:00 PM",
            team: "",
          },
        },
        {
          type: "job",
          position: { lat: 1.3662, lng: 103.8734 },
          visitStatus: "green",
          content: {
            visitId: 151,
            title: "Installation at Site B",
            priority: "Low",
            status: "Open",
            visitStatus: "green",
            time: "01/10/2024 03:00 PM - 01/10/2024 04:00 PM",
            team: ["Alex Johnson", "John Doe"],
          },
        },
        {
          type: "job",
          position: { lat: 1.3457, lng: 103.5453 },
          visitStatus: "blue",
          content: {
            visitId: 153,
            title: "Installation at Site B",
            priority: "Low",
            status: "Open",
            visitStatus: "blue",
            time: "01/10/2024 03:00 PM - 01/10/2024 04:00 PM",
            team: [" Alex Johnson", "John Doe"],
          },
        },
        {
          type: "job",
          position: { lat: 1.3762, lng: 103.7734 },
          visitStatus: "green",
          content: {
            visitId: 148,
            title: "Escalator Repair",
            priority: "Low",
            status: "Completed",
            visitStatus: "green",
            time: "12/03/2025 09:00 AM - 12/03/2025 11:00 AM",
            team: ["Johnson", "John Doe"],
          },
        },
        {
          type: "job",
          position: { lat: 1.2457, lng: 103.2453 },
          visitStatus: "blue",
          content: {
            visitId: 156,
            title: "Installation at Site B",
            priority: "Low",
            status: "Open",
            visitStatus: "blue",
            time: "01/10/2024 03:00 PM - 01/10/2024 04:00 PM",
            team: [" Alex Johnson", "John Doe"],
          },
        },
      ],
      infoWindowOpened: false,
      activeMarkerIndex: null,
      hoveredType: "",
      engineers: [
        {
          name: "John Doe",
          profile: "Chief Engineer",
          avatar: "/media/users/100_1.jpg",
          visits: 4,
          online: true,
        },
        {
          name: "Michael Brown",
          profile: "Senior Engineer",
          avatar: "/media/users/unknown.png",
          visits: 3,
          online: true,
        },
        {
          name: "Michela",
          profile: "Lead Engineer",
          avatar: "/media/users/100_2.jpg",
          visits: 2,
          online: true,
        },

        {
          name: "Anderson",
          profile: "Lead Engineer",
          avatar: "/media/users/100_3.jpg",
          visits: 3,
          online: true,
        },
        {
          name: "Michael",
          profile: "Senior Engineer",
          avatar: "/media/users/unknown.png",
          visits: 1,
          online: false,
        },
        {
          name: "Jane Smith",
          profile: "Lead Engineer",
          avatar: "/media/users/100_5.jpg",
          visits: 1,
          online: false,
        },
        // {
        //   name: "Johnson",
        //   profile: "Senior Engineer",
        //   avatar: "/media/users/100_4.jpg",
        //   visits: 4,
        //   online: true,
        // },
        // {
        //   name: "John wick",
        //   profile: "Junior Engineer",
        //   avatar: "/media/users/unknown.png",
        //   visits: 2,
        //   online: true,
        // },
        // {
        //   name: "Henry",
        //   profile: "Senior Engineer",
        //   avatar: "/media/users/unknown.png",
        //   visits: 3,
        //   online: true,
        // },
      ],
      visits: [
        {
          id: 1,
          code: "#VISIT-145",
          startDate: "18/03/2025 09:00 AM",
          endDate: "18/03/2025 10:30 AM",
          description: "Aircon Repair",
          visitStatus: "#2588e5",
        },
        {
          id: 2,
          code: "#VISIT-146",
          startDate: "19/03/2025 11:00 AM",
          endDate: "19/03/2025 12:00 AM",
          description: "Lift Repair",
          visitStatus: "#ff0000",
        },
        {
          id: 3,
          code: "#VISIT-147",
          startDate: "19/03/2025 01:00 PM",
          endDate: "19/03/2025 02:45 PM",
          description: "Server Room Repair",
          visitStatus: "#ffeb3b",
        },
        {
          id: 4,
          code: "#VISIT-148",
          startDate: "12/03/2025 09:00 AM",
          endDate: "12/03/2025 11:00 AM",
          description: "Escalator Repair",
          visitStatus: "#43A047",
        },
        {
          id: 5,
          code: "#VISIT-149",
          startDate: "20/03/2025 01:00 PM",
          endDate: "20/03/2025 03:00 PM",
          description: "Aircon Repair",
          visitStatus: "#ff9800",
        },
        {
          id: 6,
          code: "#VISIT-150",
          startDate: "21/03/2025 10:00 AM",
          endDate: "21/03/2025 11:30 AM",
          description: "Escalator Repair",
          visitStatus: "#ff0000",
        },
      ],
      viewVisitDialog: false,
      calendarDialog: true,
      toggleVisit: true,
      open_detail: false,
    };
  },
  async mounted() {
    for (const marker of this.markers) {
      if (marker.img) {
        marker.imgBase64 = await this.urlToBase64(marker.img);
      }
    }

    this.channelSubscribe(
      "engineer-tracking",
      "location.updated",
      this.locationUpdated
    );

    this.channelSubscribe(
      "engineer-tracking",
      "status.updated",
      this.statusUpdated
    );
  },
  beforeDestroy() {
    this.channelUnsubscribe(
      "engineer-tracking",
      "location.updated",
      this.locationUpdated
    );

    this.channelUnsubscribe(
      "engineer-tracking",
      "status.updated",
      this.statusUpdated
    );
  },
  methods: {
    statusUpdated(param) {
      console.log(param.data, "statusUpdated");
    },
    locationUpdated(param) {
      console.log(param.data, "locationUpdated");
    },
    filterEngineer(item) {
      this.selectEng = item.title;
      this.selectEngCount = item.count;
      this.engStatusColor = item.color;
      this.filteredEngineers = this.engineers.filter(
        (engineer) => engineer.status === item.title
      );
    },
    selectJobs(item) {
      this.selectJobStatus = item.title;
      this.selectJobCount = item.count;
      this.selectjobColor = item.color;
      this.filteredVisits = this.visits.filter(
        (visit) => visit.status === item.title
      );
    },
    openInfoWindow(index) {
      this.activeMarkerIndex = index;
      this.infoWindowOpened = true;
    },
    closeInfoWindow() {
      this.infoWindowOpened = false;
    },
    onMapReady(map) {
      console.log("Map is ready!", map);
    },
    visithoverMarker(index) {
      this.activeMarkerIndex = index;
      this.hoveredType = "visit";
      this.openInfoWindow(index);
    },
    clearVisitHoverMarker() {
      this.activeMarkerIndex = null;
      this.hoveredType = "";
      this.closeInfoWindow();
    },
    hoverMarkerEngineer(index) {
      this.activeMarkerIndex = index;
      this.hoveredType = "engineer";
      this.openInfoWindow(index);
    },
    clearHoverMarkerEngineer() {
      this.activeMarkerIndex = null;
      this.hoveredType = "";
      this.closeInfoWindow();
    },
    async urlToBase64(url) {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        return await this.blobToBase64(blob);
      } catch (error) {
        console.error("Error converting URL to Base64:", error);
        return null;
      }
    },

    blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },
    markerIcon(marker) {
      const backgroundColor = marker.visitStatus;
      const locationType = marker.type;
      const imageUrl = marker.imgBase64 || marker.img;
      if (locationType === "engineer") {
        // console.log("imageUrl", imageUrl);
        return {
          url:
            `data:image/svg+xml;charset=UTF-8,` +
            encodeURIComponent(`
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 36">
          <defs>
            <pattern id="imgPattern" patternUnits="userSpaceOnUse" width="20" height="20">
              <image href="${imageUrl}" alt="avatar" width="20" height="20" x="0" y="0" preserveAspectRatio="xMidYMid meet" />
            </pattern>
          </defs>
          <path d="M12 2C7 2 3 6 3 11c0 7.5 9 22 9 22s9-14.5 9-22c0-5-4-9-9-9zm0 13a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" fill="${backgroundColor}" />
          <circle cx="12" cy="11" r="7" fill="url(#imgPattern)" />
        </svg>
      `),
          scaledSize: {
            width: 50,
            height: 50,
          },
          labelOrigin: { x: 12, y: 36 },
        };
      } else {
        return {
          url:
            `data:image/svg+xml;charset=UTF-8,` +
            encodeURIComponent(`
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="32.000000pt" height="32.000000pt" viewBox="0 0 32.000000 32.000000"
          preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
            fill="${backgroundColor}" stroke="none">
            <path d="M113 310 c-15 -6 -20 -15 -16 -30 3 -11 9 -23 14 -26 16 -10 9 -42
            -16 -72 -39 -46 -30 -76 25 -84 22 -3 25 -9 28 -45 4 -55 20 -55 24 -1 3 37 6
            43 28 46 55 8 64 38 25 84 -29 34 -30 45 -10 84 8 16 11 32 6 37 -13 13 -83
            18 -108 7z"/>
          </g>
        </svg>
      `),
          scaledSize: {
            width: 30,
            height: 30,
          },
          labelOrigin: { x: 12, y: 36 },
        };
      }
    },
    // isHovering(index) {
    //   return this.activeMarkerIndex === index;
    // },
  },
  computed: {
    filteredEngineers() {
      const searchQuery = this.engineer_search?.toLowerCase() || "";
      return this.engineers.filter((engineer) => {
        const name = engineer.name?.toLowerCase() || "";
        return name.includes(searchQuery);
      });
    },
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 40);
    },
  },
};
</script>
<style scoped>
.custom-info-window {
  position: relative;
}

.gm-style .gmnoprint .gm-close-button {
  display: none !important;
}
.active-card {
  /* transform: scale(1.02); */
  background-color: #008cff27;
}
.map-filter {
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  z-index: 3;
  margin: auto;
}
.v-list > .v-list-item:nth-child(even) {
  background: #fbf9f5;
}
.v-list > .v-list-item {
  border-bottom: 1px solid #f1ecec;
}
.legent-list {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background: #fff;
}
</style>
